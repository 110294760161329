import { useWeb3React } from '@telekomconsalting/core'
import classNames from 'classnames'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import {
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget,
  ResolutionString,
  widget,
} from '../../charting_library'
import { TOLERATED_OFFLINE_TIME_WITHOUT_CHART_REFRESH } from '../../config/settings'
import { TVCHART_MAIN_LABEL } from '../../constants'
import { useComponentDidMount } from '../../hooks/useComponentDidMount'
import { usePrevWebsocketState } from '../../hooks/usePrevWebsocketState'
import { TokenChartData } from '../../model'
import { State } from '../../reducers'
import Loader from '../Loader'
import { liquidityIndicatorName } from './indicators/liquidity'
import ChartResources, { defaultOverrides } from './resources/TVChartResources'

const TVChartResources = ChartResources(TVCHART_MAIN_LABEL)

interface TVChartContainerProps {
  symbolName: string
  isReadOnlyMarket?: boolean
  saveHeight: (value: string) => void
  hideResizeButton: boolean
}

const getResizeHandler =
  (props: TVChartContainerProps): ResizeObserverCallback =>
  (entries): void => {
    window.requestAnimationFrame(() => {
      for (const entry of entries) {
        let boxSize: ResizeObserverSize | undefined = undefined
        // in Chrome entry.contentBoxSize is array: ResizeObserverSize[]
        if (
          entry.contentBoxSize &&
          Array.isArray(entry.contentBoxSize) &&
          entry.contentBoxSize.length > 0
        ) {
          boxSize = entry.contentBoxSize[0]
        }
        // in Firefox entry.contentBoxSize is an object: ResizeObserverSize
        if (entry.contentBoxSize && !Array.isArray(entry.contentBoxSize)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          boxSize = entry.contentBoxSize
        }
        if (boxSize && boxSize.blockSize) {
          props.saveHeight(`${boxSize.blockSize}px`)
        }
      }
    })
  }

const onAutoSaveNeededHandler = (tvWidget: IChartingLibraryWidget) => {
  return (): void => {
    const currentSymbolExt = tvWidget.activeChart().symbolExt()
    const symbolInterval = tvWidget.symbolInterval()
    tvWidget.save((chartState) => {
      // in some cases currentSymbolExt is null and throws error in console
      if (!currentSymbolExt?.full_name) {
        return
      }

      localStorage.setItem(currentSymbolExt.full_name, JSON.stringify(chartState))

      // saves interval separately so on init load (getLastIntervalForSymbol) we can extract it and pass to widget constructor
      localStorage.setItem(
        `${currentSymbolExt.full_name}__last_interval`,
        JSON.stringify(symbolInterval.interval)
      )
    })
  }
}

const getSavedData = (symbol?: string): TokenChartData | null => {
  if (!symbol) {
    return null
  }
  const savedString = localStorage.getItem(symbol)
  if (!savedString) {
    return null
  }
  return JSON.parse(savedString)
}

const getLastIntervalForSymbol = (symbol: string): ResolutionString | undefined => {
  const lastIntervalForSymbol = localStorage.getItem(`${symbol}__last_interval`)
  if (!lastIntervalForSymbol) {
    return undefined
  }
  return JSON.parse(lastIntervalForSymbol) as ResolutionString
}

/**
 * Currently just disable/enable save button (cloud) for authenticated users
 * @param account
 */
const getDisabledFeaturesets = (account?: string | null): string[] =>
  account
    ? TVChartResources.disabled_features || []
    : (TVChartResources.disabled_features || []).concat(['header_saveload'])

// prevent appearing of such an ugly requests like https://api.dex.guru/v1/tradingview/history?symbol=0x44c098782990966ec3ad27a7fa43947358eeb2c3-bsc_USD&resolution=10&from=%5Bobject%20Object%5D&to=(e%2Ct)%3D%3E%7Bif(!this._destroyed)if(a)this._logMessage(%22getBars%20callback%20is%20already%20called%20before%22%2C!0)%3Belse%7Bif(a%3D!0%2Ce.length%3E0)%7Bconst%20t%3D%60%20%5B%24%7Bd(e%5B0%5D.time)%7D%20...%20%24%7Bd(e%5Be.length-1%5D.time)%7D%5D%60%3Bthis._logMessage(%60Receiving%20bars%3A%20total%20%24%7Be.length%7D%20bars%20in%20%24%7Bt%7D%2C%20requested%20range%3A%20%24%7Bo%7D%60)%7Delse%20this._logMessage(%22Receiving%20bars%3A%20barset%20is%20empty%2C%20requested%20range%3A%20%22%2Bo)%3Bthis._requesting%3D!1%2Cthis._processBars(e%2Ct)%7D%7D
// should be called only once, when user had localtorage from charting library v.18
const shouldDropTradingviewChartproperties =
  window.localStorage.getItem('didDropTradingviewChartproperties') !== 'true'
if (shouldDropTradingviewChartproperties) {
  window.localStorage.removeItem('tradingview.chartproperties')
  window.localStorage.setItem('didDropTradingviewChartproperties', 'true')
}

const TVChartContainerFunc: FC<TVChartContainerProps> = (props) => {
  const { account } = useWeb3React()
  const containerRef = useRef<HTMLDivElement>(null)
  const widgetRef = useRef<IChartingLibraryWidget | undefined | null>(undefined)
  const isGlobalLoader = useSelector((state: State) => state.loader.isGlobalLoaderVisible)

  const [isLoading, setIsLoading] = useState(false)
  const [lastOffline, setLastOffline] = useState<number | undefined>(undefined) // timestamp
  const defaultProps = TVChartResources

  const getWidgetConfig = useCallback((): ChartingLibraryWidgetOptions => {
    if (widgetRef.current) {
      widgetRef.current.remove()
    }

    const lastIntervalForSymbol = getLastIntervalForSymbol(props.symbolName)
    return {
      ...TVChartResources,
      symbol: props.symbolName,
      disabled_features: getDisabledFeaturesets(account),
      user_id: account || undefined,
      container:
        containerRef && containerRef.current ? containerRef.current : defaultProps.container,
      interval: lastIntervalForSymbol || TVChartResources.interval,
    }
  }, [account, defaultProps.container, props.symbolName])

  const getOnChartReadyHandler = (): void => {
    if (!widgetRef.current) {
      return
    }

    const currentSymbolExt = widgetRef.current.activeChart().symbolExt()

    widgetRef.current.applyOverrides(defaultOverrides)

    // first load charts settings if they exists (timeframe, indicators etc.)
    const savedData = getSavedData(currentSymbolExt.full_name)
    if (savedData) {
      widgetRef.current.load(savedData)
    }

    // important: add custom studies when loaded saved data!
    if (
      !widgetRef.current
        .activeChart()
        .getAllStudies()
        .some((x) => x.name === liquidityIndicatorName)
    ) {
      widgetRef.current.activeChart().createStudy(liquidityIndicatorName)
    }
    // Then subscribed to the autosave event.
    // The thing is that it is fired even when you just call setSymbol
    widgetRef.current.subscribe('onAutoSaveNeeded', onAutoSaveNeededHandler(widgetRef.current))

    setIsLoading(false)
  }

  const updateWidgetIfOfflineForLong = (): void => {
    if (
      widgetRef.current &&
      lastOffline &&
      new Date().getTime() - lastOffline > TOLERATED_OFFLINE_TIME_WITHOUT_CHART_REFRESH
    ) {
      widgetRef.current.activeChart().resetData()
    }
  }

  const initWidget = useCallback(() => {
    setIsLoading(true)
    widgetRef.current && widgetRef.current.remove()
    const widgetOptions = getWidgetConfig()
    const widgetInstance = new widget(widgetOptions)
    widgetRef.current = widgetInstance
    widgetRef.current.onChartReady(getOnChartReadyHandler)
  }, [getWidgetConfig])

  usePrevWebsocketState(initWidget)

  useComponentDidMount(() => {
    /**
     * Subscribe on events when tab is stale and chart data wasn't updated for a while.
     * When user returns back to the app, we have to re-request chart data
     */
    const onTabOfflineOfflineHandler = (): void => {
      setLastOffline(new Date().getTime())
    }
    window.addEventListener('offline', onTabOfflineOfflineHandler)
    window.addEventListener('online', updateWidgetIfOfflineForLong)
    // For freeze/resume
    // https://developers.google.com/web/updates/2018/07/page-lifecycle-api
    // https://wicg.github.io/page-lifecycle/
    document.addEventListener('freeze', onTabOfflineOfflineHandler)
    document.addEventListener('resume', updateWidgetIfOfflineForLong)

    const resizeObserver = new ResizeObserver(getResizeHandler(props))
    const containerDiv = containerRef && containerRef.current
    containerDiv && resizeObserver.observe(containerDiv)

    return (): void => {
      if (containerDiv) {
        resizeObserver.unobserve(containerDiv)
      }

      window.removeEventListener('offline', onTabOfflineOfflineHandler)
      window.removeEventListener('online', updateWidgetIfOfflineForLong)
      document.removeEventListener('freeze', onTabOfflineOfflineHandler)
      document.removeEventListener('resume', updateWidgetIfOfflineForLong)

      if (widgetRef.current) {
        widgetRef.current.remove()
        widgetRef.current = null
      }
    }
  })

  useEffect(() => {
    if (isGlobalLoader) {
      setIsLoading(true)
      return
    }

    try {
      const activeChart = widgetRef.current && widgetRef.current.activeChart()

      if (!activeChart) {
        initWidget()
        return
      }

      setIsLoading(true)
      activeChart.setSymbol(props.symbolName, () => {
        // This should be called before onAutoSaveNeeded handler.
        // So that handler is delayed by setting auto_save_delay to ~5sec
        const savedData = getSavedData(props.symbolName)
        if (savedData) {
          widgetRef.current && widgetRef.current.load(savedData)
        }
        setIsLoading(false)
      })
    } catch (err: ReturnType<Error>) {
      console.error(err.message, err)
    }
  }, [props.symbolName, isGlobalLoader, initWidget])

  useEffect(() => {
    if (widgetRef.current) {
      // there is no other way to enable|disable save button (cloud). only re-init of the widget
      initWidget()
    }
  }, [account, props.hideResizeButton, initWidget])

  useEffect(() => {
    if (widgetRef.current && props.hideResizeButton) {
      initWidget()
    }
  }, [props.hideResizeButton, initWidget])

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <div
        ref={containerRef}
        id={defaultProps.container as string}
        className={classNames(
          'tv-chart',
          {
            'not-resizeable': props.hideResizeButton,
          },
          { resizable: !props.hideResizeButton }
        )}
      />
    </React.Fragment>
  )
}

export default React.memo(TVChartContainerFunc, (prevProps, nextProps) => {
  if (prevProps.hideResizeButton !== nextProps.hideResizeButton) {
    return false
  }
  if (prevProps.isReadOnlyMarket !== nextProps.isReadOnlyMarket) {
    return false
  }
  if (prevProps.symbolName !== nextProps.symbolName) {
    return false
  }
  return true
})
